<template>
  <div class="flex justify-center items-center">
    <div class="bg-oldburgundy p-4 rounded-lg">
      <div v-if="isLoggedIn" class="text-indianyellow">
        <h1 class="text-center">Welcome to LudusCristaltec DB Uploader</h1>
        <p class="text-center">You are logged in as <span class="font-bold">{{this.role}}</span></p>
      </div>
      <div class="w-full max-w-xs" v-else>
        <form  v-if="loginForm" class=" rounded px-8 pt-2 pb-4 mb-4" @submit.prevent="submitLogin">
          <div class="mb-4">
            <label class="block text-white text-sm font-bold mb-2" for="email">
              Email
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:border-indianyellow outline-none text-sm" id="email" type="text" placeholder="email@ludustechnology.com" v-model="email">
          </div>
          <div class="mb-2">
            <label class="block text-white text-sm font-bold mb-2" for="password">
              Password
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:border-indianyellow outline-none text-sm" id="password" type="password" placeholder="******************" required v-model="password">
          </div>
          <div class="flex items-center justify-around">
            <button class="text-white font-bold py-2 px-4 rounded border hover:text-indianyellow hover:border-indianyellow text-sm" type="submit">
              Sign In
            </button>
            <button class="inline-block align-baseline  font-bold text-xs text-white hover:text-indianyellow hover:border-indianyellow" @click.prevent="forgotPassword(email)">
              Forgot Password?
            </button>
          </div>
        </form>
        <LudusAuth></LudusAuth>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import axios from "axios";
  import router from "../router";

  import {LudusAuth} from "@ludus-npm/vue-auth"

export default {
  name: 'Home',
  components: {
    LudusAuth: LudusAuth
  },
  data () {
    return {
      email: '',
      password: '',
      loginForm: false
    }
  },
  computed: {
    ...mapState([
      'isLoggedIn',
      'role',
      'error'
    ])
  },
  methods: {
    ...mapActions([
      'doLogin',
      'doDismissError'
    ]),
    submitLogin () {
      this.doLogin({
        email: this.email,
        password: this.password
      })
    },
    forgotPassword (email) {
      this.clearInfo()
      if (confirm('This will send a recovery email to ' + email + ' Proceed?')) {
        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/resetPassword`, {
            email: email
          },
          {
            headers: {
              'x-access-token': this.getToken()
            }
          })
          .then((response) => {
            if (response.status === 200) {
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.error = error
            console.log(error.response)
          })
      }
    },
  },
  mounted() {
    if(this.isLoggedIn){
      router.push({name: 'Home'})
    }
    window.addEventListener('keyup', ev => {
      if (ev.keyCode === 27) {

        this.loginForm = !this.loginForm
      }
    })
  },
  destroyed: function() {
    window.removeEventListener('keyup');
  }
}
</script>


<style scoped>
</style>
