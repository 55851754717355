<template>
  <div id="app" class="min-h-screen flex flex-col bg-oldburgundy">
    <db-uploader-navbar></db-uploader-navbar>
    <router-view class="flex-grow p-8 text-2xl bg-cover bg-fixed bg-center" :style="{ backgroundImage: 'url(' + require('@/assets/bg3.jpg') + ')' }"/>
    <db-uploader-footer></db-uploader-footer>
  </div>
</template>

<script>

import navbar from './Navbar'
import footer from './components/Footer'

export default {
  name: 'App',
  components: {
    dbUploaderNavbar: navbar,
    dbUploaderFooter: footer
  }
}
</script>

<style>
</style>
