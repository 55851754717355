<template>
  <div class="flex justify-center items-center ">
    <div class="flex flex-col bg-oldburgundy p-4 rounded-lg relative w-2/5" style="height: 66vh">
      <div class="w-full text-indianyellow font-bold text-md text-center mb-2">Logs</div>
      <div class="divide-y-2 divide-indianyellow overflow-y-auto mb-6" >
        <div class="flex flex-row flex-wrap items-center justify-between m-1" v-for="(log, index) in logs" :key="index">
          <div class="text-white text-sm ">{{log.message}}</div>
          <div class="text-white text-sm ">{{log.createdAt | formatDate}}</div>
        </div>
      </div>
      <pagination-footer :currentPage="currentPage" :lastPage="lastPage" @previous-page="previousPage" @next-page="nextPage"></pagination-footer>

    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from "axios";
  import PaginationFooter from "../components/PaginationFooter";

  export default {
    name: "Log",
    data() {
      return {
        logs: [],
        currentPage: 1,
        lastPage: null,
        pageSize: 15
      }
    },
    components: {
      paginationFooter : PaginationFooter
    },
    methods: {
      ...mapGetters([
        'getToken'
      ]),
      getLogs() {
        let queryParams = '?'
        if(this.currentPage){
          queryParams += `page=${this.currentPage}`
        }
        if(this.pageSize){
          queryParams += `&size=${this.pageSize}`
        }

        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/log${queryParams}`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.logs = response.data.data
            this.lastPage = response.data.last_page
            this.currentPage = response.data.current_page
          })
          .catch(error => {
            this.$toasted.error(error)
          })
      },
      nextPage () {
        this.currentPage+=1
        this.getLogs()
      },
      previousPage() {
        this.currentPage-=1
        this.getLogs()
      }
    },
    mounted(){
      this.getLogs()
    }
  }
</script>

<style scoped>

</style>