<template>
  <footer id="footer" class="w-full text-center text-indianyellow p-4 pin-b">
    © Copyright {{year}} | LudusCristaltec
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>

<style scoped>

</style>