<template>
  <div class="flex justify-center items-center">
    <div class="flex justify-around bg-oldburgundy p-4 rounded-lg divide-x-2 divide-indianyellow w-1/2">
      <div class="flex-col w-1/2 px-2">
        <div class="w-full text-indianyellow font-bold text-md text-center mb-2">Upload Encrypted DBs</div>

        <div class="flex-col justify-center items-center">
          <div class="text-sm text-white"> Please select one (or multiple) db bundles. The bundles can be in .zip or .tar.gz format.</div>
          <div class="text-sm text-white text-center">Each bundle should contain all the .bin files at the root:</div>
        </div>
        <div class="flex items-center justify-center">
          <div class="flex-col justify-start items-center text-sm text-indianyellow">
            <div>bundle.zip</div>
            <div>- db.bin</div>
            <div>- anotherdb.bin</div>
          </div>
        </div>
        <div class="flex justify-center items-center py-3 mr-3">
          <button class="rounded border hover:text-indianyellow hover:border-indianyellow text-white font-bold inline-flex items-center py-2 px-5">
            <svg fill="#FFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
            </svg>
            <span class="hover:border-indianyellow ml-2">Select Dbs</span>
          </button>
          <input class="hover:border-indianyellow cursor-pointer absolute opacity-0" type="file" name="databases" ref="databases" @change="handleDatabasesUpload" multiple>
        </div>


        <div class="flex flex-col items-center px-4 divide-y-2 divide-indianyellow overflow-y-auto w-auto inline-block" style="height: 25vh">
          <div v-for="(encryptedDb, index) in encryptedDbs" :key="index">
            <div class="text-white text-sm py-2">{{encryptedDb.name}}</div>
          </div>
        </div>

        <div class="flex justify-center items-center mt-2 pr-4">
          <moon-loader v-if="dbsUploading" :color=uploadSpinner.color :size="uploadSpinner.size"></moon-loader>
          <span class="ml-2 text-indianyellow" v-if="dbsUploading">{{uploadPercentage}}</span>
          <button v-if="encryptedDbs.length>0 && !dbsUploading" type="submit" class="rounded border hover:text-indianyellow hover:border-indianyellow text-white font-bold inline-flex items-center py-2 px-5" @click.prevent="uploadDatabases">Upload</button>
        </div>
      </div>

      <div class="flex-col w-1/2 relative" style="height: 66vh">
        <div class="w-full text-indianyellow font-bold text-md text-center mb-2"> Decrypted DBs</div>

        <div class="flex-col items-center divide-y-2 divide-indianyellow overflow-y-auto px-2" >
          <div class="flex flex-wrap md:justify-between xs:justify-center items-center text-white text-sm py-2" v-for="(decryptedDb, index) in decryptedDbs" :key="index">

              <div class="flex-col">
                <p>{{decryptedDb.name}}</p>
                <p>{{decryptedDb.createdAt | formatDate}}</p>
              </div>
              <div class="flex items-center">
                <button v-if="decryptedDb.decryptionStatus.name === 'stored'" type="button"
                        class="rounded border hover:text-indianyellow hover:border-indianyellow text-white font-bold py-2 px-5"
                        @click.prevent="downloadDb(decryptedDb.id, decryptedDb.name)"
                >
                  Download
                </button>
                <div v-else-if="decryptedDb.decryptionStatus.name === 'error'" class="text-red-600 font-bold">ERROR</div>
                <div class="flex items-center " v-else>
                  <div class="mr-2">{{decryptedDb.decryptionStatus.name}}...</div>
                  <moon-loader  :color=processingSpinner.color :size="processingSpinner.size"></moon-loader>
                </div>
              </div>

          </div>
        </div>
        <pagination-footer :currentPage="currentPage" :lastPage="lastPage" @previous-page="previousPage" @next-page="nextPage"></pagination-footer>
      </div>

    </div>
  </div>
</template>

<script>
  import MoonLoader from "vue-spinner/src/MoonLoader";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import PaginationFooter from "../components/PaginationFooter";
  export default {
    name: "dbDecrypt",
    data (){
      return {
        uploadPercentage: 0 + '%',
        uploadSpinner: {
          color: '#de9e48',
          radius: '50%',
          size: '30px',
          margin: '30px',
        },
        processingSpinner: {
          color: '#de9e48',
          radius: '50%',
          size: '30px',
          margin: '30px',
        },
        encryptedDbs:[],
        decryptedDbs: [
        ],
        dbsUploaded: false,
        dbsUploading: false,
        currentPage: 1,
        lastPage: null,
        refreshInterval: null
      }
    },
    components: {
      MoonLoader,
      paginationFooter: PaginationFooter
    },
    methods: {
      handleDatabasesUpload(){
        this.dbsUploaded= false
        this.encryptedDbs = this.$refs.databases.files
      },
      uploadDatabases() {
        this.dbsUploading = true
        let formData = new FormData()

        //TODO OPTIMIZE
        for( let i = 0; i < this.encryptedDbs.length; i++ ){
          let file = this.encryptedDbs[i];
          formData.append('databases', file);
        }

        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/upload/encrypted`,
        formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              //...formData.getHeaders(),
              'x-access-token': this.getToken()
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(Math.round( (progressEvent.loaded / progressEvent.total) * 100) )
              if(this.uploadPercentage !== 100) {
                this.uploadPercentage += '%'
              }
            }.bind(this)
          })
        .then((response) => {
          console.log(response.data.message)
          this.dbsUploading = false
          this.dbsUploaded = true
          this.encryptedDbs = []
          this.$toasted.success(response.data.message)
        })
        .catch(error => {
          this.dbsUploading = false
          this.$toasted.error(error.response)
        })
      },
      ...mapGetters([
        'getToken'
      ]),
      getDbs() {
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/encrypted-db/db?page=${this.currentPage}`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
        .then(response => {
          this.decryptedDbs = response.data.data
          this.lastPage = response.data.last_page
          this.currentPage = response.data.current_page
        })
        .catch(error=> {
          console.log(error)
          this.$toasted.error(error)
        })
      },
      nextPage () {
        this.currentPage+=1
        this.getDbs()
      },
      previousPage() {
        this.currentPage-=1
        this.getDbs()
      },
      downloadDb(dbId, dbname) {
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/encrypted-db/download/${dbId}`,
          {
            headers: {
              'x-access-token': this.getToken()
            },
            responseType: 'blob'
          })
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            if (dbname.includes('.tar.gz')) {
              dbname = dbname.replace('.tar.gz', '.zip')
              console.log(dbname)
            } else{
              dbname += '.zip'
            }
            fileLink.setAttribute('download', 'processed_' +dbname);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(error => {
            console.log(error.response)
          })
      },
    },
    created(){
      this.getDbs()
      this.refreshInterval = setInterval(this.getDbs, 10000)
    },
    beforeDestroy() {
      clearInterval(this.refreshInterval)
    }

  }
</script>

<style scoped>

</style>
