<template>
  <div class="flex flex-wrap flex-row  justify-center items-center p-4 rounded-lg relative divide-x-2 divide-indianyellow ">


    <div class="flex-col bg-oldburgundy w-2/5 rounded-l-lg" style="height: 66vh">
      <div class="text-center text-indianyellow pb-2 font-bold">Products</div>

      <div class="flex justify-around">
        <div class="flex justify-between overflow-y-auto divide-x-2 divide-indianyellow w-1/2" style="height: 60vh">
          <div class="flex-col justify-start items-start divide-y-2  divide-indianyellow w-full pr-2"
               :class="{
                      'opacity-50': selectedGame
             }">
            <div class="text-sm text-white text-center"
                 v-for="product of products" :key="product.id" >
              <button  class="w-full outline-none hover:bg-acajou focus:outline-none"
                       :class="{ 'bg-russet': selectedProduct && product.id === selectedProduct.id }"
                       @click.prevent="pickProduct(product)">
                {{product.visibleName}}
              </button>
            </div>
          </div>
        </div>

        <div class="flex-col justify-between overflow-y-auto divide-indianyellow w-2/5"
             style="height: 60vh"
             :class="{
                      'opacity-50': selectedGame
             }"
        >
          <div v-if="selectedProduct" class="flex-col w-full divide-y-2 text-center text-base">
              <div class="text-center font-bold text-indianyellow "> {{selectedProduct.visibleName}}</div>
              <div class="flex items-center ">
                <div class="font-bold text-indianyellow w-1/2">Name</div><div class="text-white w-1/2">{{selectedProduct.name}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">Visible Name</div><div class="text-white w-1/2">{{selectedProduct.visibleName}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2 ">CODSW</div><div class="text-white w-1/2 overflow-x-auto">{{selectedProduct.codsw}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">Payout</div><div class="text-white w-1/2">{{selectedProduct.payoutPercentage/100}}%</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">InKey</div><div class="text-white w-1/2">{{selectedProduct.inKeyPosition}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">OutKey</div><div class="text-white w-1/2">{{selectedProduct.outKeyPosition}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">Created</div><div class="text-white w-1/2">{{selectedProduct.createdAt | formatDate}}</div>
              </div>
              <div class="flex items-center">
                <div class="font-bold text-indianyellow w-1/2">Updated</div><div class="text-white w-1/2">{{selectedProduct.updatedAt | formatDate}}</div>
              </div>
          </div>
          <div v-if="selectedProduct" class="flex-col w-full divide-y-2 text-center text-base">
            <div class="text-center font-bold text-indianyellow">Games</div>
            <button v-for="productGamePosition in selectedProduct.productGamePositions" :key="productGamePosition.id"
                    @click.prevent="pickProductGame(productGamePosition)"
                    class="flex items-center w-full outline-none hover:bg-acajou focus:outline-none"
                    :class="{
                      'bg-russet': selectedProductGame && productGamePosition.id === selectedProductGame.id
                    }">
              <div class="font-bold text-indianyellow w-1/2">Position {{productGamePosition.position}}</div><div class="text-white w-1/2">{{productGamePosition.game.name}}</div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center bg-oldburgundy w-1/5" style="height: 66vh">
      <div v-if="selectedProductGame && selectedProductGame" class="flex-col w-full divide-y-2 text-center text-base p-1">
        <div  class="text-center text-indianyellow pb-2 font-bold  text-xl">{{selectedProduct.visibleName}}'s {{selectedProductGame.game.name}} Stats</div>
        <div v-if="selectedGameLicense" class="divide-y-2">


        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2"># Boards</div><div class="text-white overflow-x-auto w-1/2">{{selectedGameLicense.count}}</div>
        </div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2">IN</div><div class="text-white w-1/2">{{selectedGameLicense.totalIn/100}}€</div>
        </div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2">OUT</div><div class="text-white w-1/2">{{selectedGameLicense.totalOut/100}}€</div>
        </div>
        </div>
      </div>
      <div v-else-if="selectedGame && selectedGameCounters" class="flex-col w-full divide-y-2 text-center text-base p-1">
        <div  class="text-center text-indianyellow pb-2 font-bold  text-xl">{{selectedGame.name}} Stats</div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2"># of Products</div><div class="text-white overflow-x-auto w-1/2">{{selectedGame.productGamePositions.length}}</div>
        </div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2"># Boards</div><div class="text-white w-1/2">{{selectedGameCounters.count}}</div>
        </div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2">TOTAL IN</div><div class="text-white w-1/2">{{selectedGameCounters.totalIn/100}}€</div>
        </div>
        <div class="flex items-center ">
          <div class="font-bold text-indianyellow w-1/2">TOTAL OUT</div><div class="text-white w-1/2">{{selectedGameCounters.totalOut/100}}€</div>
        </div>
      </div>

    </div>


    <div class="flex flex-col items-center bg-oldburgundy w-1/8 rounded-r-lg" style="height: 66vh">
      <div class="text-center text-indianyellow pb-2 font-bold">Games</div>
      <div class="flex justify-around px-2 ">
        <div class="flex justify-between overflow-y-auto divide-x-2 divide-indianyellow pr-2" style="height: 60vh">
          <div class="flex-col justify-start items-start divide-y-2  divide-indianyellow w-full">
            <div class="text-sm text-white text-center"
                 v-for="game of games" :key="game.id" >
              <button  class="w-full outline-none hover:bg-acajou focus:outline-none"
                       :class="{
                          'bg-russet': selectedGame && game.id === selectedGame.id,
                           'opacity-50': selectedProductGame
                        }"
                       @click.prevent="pickGame(game)">
                {{game.name}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "ManageSystem",
    data() {
      return {
        products: '',
        games: '',
        selectedProduct: null,
        selectedProductGame: null,
        selectedGameLicense: null,
        selectedGame: null,
        selectedGameCounters: null
      }
    },
    methods: {
      ...mapGetters([
        'getToken'
      ]),
      pickProduct(product){
        this.selectedProductGame = null
        this.selectedGame = null
        this.selectedProduct = product
      },
      pickProductGame(productGame){
        this.selectedGame = null
        this.selectedProductGame = productGame
        this.selectedGameLicense = null
        this.getLicenseGameCounter(productGame.id)

      },
      pickGame(game) {
        this.selectedProductGame = null
        this.selectedGameCounters = null
        this.selectedGame = game
        this.getCounters(game)
      },
      getCounters(game){
        const idArray = game.productGamePositions.map((element) => `${element.id}&`)
        idArray[idArray.length -1] = idArray[idArray.length -1].replace('&', '')

        let queryParam = ''
        if(idArray.length === 1){
          queryParam = 'productPositionId=' + idArray[0]
        } else{
          queryParam = idArray.join('productPositionId=')
        }

        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/game/counters?${queryParam}`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.selectedGameCounters = response.data
          })
          .catch(error => {
            this.$toasted.error(error)
          })



      },
      getProducts() {
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/product`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.products = response.data.products
          })
          .catch(error => {
            this.$toasted.error(error)
          })
      },
      getLicenseGameCounter(productGameId) {
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/license-game-counter/product-game-position/${productGameId}`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.selectedGameLicense = response.data

          })
          .catch(error => {
            this.$toasted.error(error)
          })
      },
      getGames(){
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/game`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.games = response.data.games
          })
          .catch(error => {
            this.$toasted.error(error)
          })
      }
    },
    created() {
      this.getProducts()
      this.getGames()
    }
  }
</script>

<style scoped>

</style>