import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import './assets/tailwind.css'
import interceptorsSetup from './helpers/interceptors'
import Toasted from '@gitlab/vue-toasted'
import { DateTime } from "luxon";




const toastedConfig = {
  position: 'top-center',
  duration: 3000,
  keepOnHover: true,
}

Vue.use(Toasted, toastedConfig)



Vue.filter('formatDate', function(date) {
  return DateTime.fromISO(date).toFormat('D HH:mm:ss')
})
Vue.filter('humanizeDate', function(date) {
  return DateTime.fromISO(date).toRelative()
})
Vue.filter('formatDateFromJs', function(date) {
  return DateTime.fromJSDate(date).toFormat('D HH:mm:ss')
})

Vue.config.productionTip = false


interceptorsSetup()
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
