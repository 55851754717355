import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import router from "../router";

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isLoggedIn: false,
    name: null,
    email: null,
    error: null,
    jwtToken: null,
    refreshToken: null,
    role: null
  },
  getters: {
    loggedIn: state => state.isLoggedIn,
    getName: state => state.name,
    getEmail: state => state.email,
    getRole: state => state.role,
    getToken: state => state.jwtToken,
    getRefreshToken: state => state.refreshToken
  },
  mutations: {
    loginStop: (state, payload) => {
      state.isLoggedIn = !payload.errorMessage
      state.error = payload.errorMessage
      state.jwtToken = payload.token || null
      state.refreshToken = payload.refreshToken || null
      state.name = payload.name || null
      state.email = payload.email || null
      state.role = payload.role? payload.role : 'user'

      if(payload.errorMessage){
        Vue.toasted.error(payload.errorMessage)
      }else{

        router.push({name: 'Home'})
      }
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.error = null
      state.jwtToken = null
      state.refreshToken = null
      state.name = null
      state.email = null
      state.role = null
    },
    clearError: (state) => {
      state.error = null
    },
    error: (state, payload) => {
      state.error = payload
    },
    updateToken: (state, payload) => {
      state.jwtToken = payload.token
    }
  },
  actions: {
    doLogin ({ commit }, loginData) {
      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/signin`, {
        email: loginData.email || '',
        password: loginData.password || ''
      })
        .then((response) => {
          console.log(response.status)
          commit('loginStop', {
            errorMessage: null,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            name: response.data.name,
            role: response.data.role,
            email: response.data.email
          })
          commit('clearError')

        })
        .catch(error => {
          console.log(error.response)
          commit('loginStop', {
            errorMessage: error.response.data.error,
            token: null,
            refreshToken: null,
            name: null,
            role: null
          })
        })
    },
    doLoginOauthUser({ commit }, loginData){
      commit('loginStop', {
        errorMessage: null,
        token: loginData.token,
        refreshToken: loginData.refreshToken,
        name: loginData.name,
        role: loginData.role,
        email: loginData.email
      })
    },
    getNewToken ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/auth/refresh`, {
          refreshToken: payload.refreshToken,
          email: payload.email
        })
          .then(response => {
            commit('updateToken', {
              token: response.data.token
            })
            resolve(response.data.token)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    doLogout ({ commit }) {
      commit('logout')
    },
    doDismissError ({commit}) {
      commit('clearError')
    },
    doUpdateToken ({ commit }, token) {
      commit('updateToken', {
        token: token
      })
    }
  },
  modules: {
  }
})
