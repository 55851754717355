import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home";
////import OauthCallback from "../views/OauthCallback";
import DbDecrypt from "../views/DbDecrypt";
import Log from "../views/Log";
import { store } from '../store'
import { Role } from '../helpers/roles';
import ManageSystem from "../views/ManageSystem";
import NewProduct from "../views/NewProduct";
import { OauthCallback } from "@ludus-npm/vue-auth"



Vue.use(VueRouter)

const routesAuthorization = {
  DbDecrypt: [Role.Admin, Role.Operator, Role.User],
  Logs: [Role.Admin, Role.Operator],
  SystemInfo: [Role.Admin, Role.Operator],
  NewProduct: [Role.Admin, Role.Operator]
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/oauth/callback',
    name: 'OauthCallback',
    component: OauthCallback
  },
  {
    path: '/decrypt',
    name: 'DbDecrypt',
    component: DbDecrypt,
    meta: { authorize: routesAuthorization.DbDecrypt }
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Log,
    meta: { authorize: routesAuthorization.Logs }
  },
  {
    path: '/system-info',
    name: 'SystemInfo',
    component: ManageSystem,
    meta: { authorize: routesAuthorization.SystemInfo }
  },
  {
    path: '/new-product',
    name: 'NewProduct',
    component: NewProduct,
    meta: { authorize: routesAuthorization.NewProduct}
  },
  { path: '*', redirect: '/' }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.routesAuthorization = routesAuthorization

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUserRole = store.getters.getRole;

  if (authorize) {
    if (!currentUserRole) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUserRole)) {
      // role not authorised so redirect to home page
      console.log('unauthorized')
      return next({ path: '/' });
    }
  }

  next();
})

export default router
