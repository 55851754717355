<template>
  <div class="flex flex-wrap flex-row  justify-center items-center p-4 rounded-lg relative divide-x-2 divide-indianyellow">
    <div class="flex-col text-center text-indianyellow font-bold bg-oldburgundy w-1/4 rounded-l-lg p-2 overflow-y-auto" style="height: 66vh">
      <div>New Product</div>
      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">Name</div>
        <input type="text" class="text-white w-1/3 bg-russet rounded outline-none text-center" v-model="productName" required/>
      </div>
      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">Visible Name</div>
        <input type="text" class="text-white w-1/3 bg-russet rounded outline-none text-center" v-model="productVisibleName" required/>
      </div>

      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">CODSW</div>
        <input type="text" class="text-white w-1/3 bg-russet rounded outline-none text-center" v-model="productCodsw" required/>
      </div>

      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">Payout (4 digits)</div>
        <input type="number" class="text-white w-1/4 bg-russet rounded outline-none text-center" v-model="productPayout" required/>
      </div>

      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">InKey</div>
        <input type="number" min="1" class="text-white w-1/6 bg-russet rounded outline-none text-center" v-model="productInKey" required/>
      </div>

      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2">OutKey</div>
        <input type="number" min="1" class="text-white w-1/6 bg-russet rounded outline-none text-center" v-model="productOutKey" required/>
      </div>


      <div class="flex items-center justify-between text-base p-1">
        <div class="font-bold text-indianyellow w-1/2 outline-none"># Of Games</div>
        <input type="number" min="1" class="text-white w-1/6 rounded outline-none text-center bg-russet" v-model="numberOfGames" required/>
      </div>

      <div v-for="(newGame, index) in gamesArray" :key="index">
        <div class="flex items-center justify-between text-base p-1">
          <div class="font-bold text-indianyellow w-1/2">Position {{index}}</div>
            <div v-if="newGame.game" class="w-1/3 text-center text-white ">{{newGame.game.name}}</div>
            <div v-else class="relative inline-block text-left w-1/3 justify-center">
              <div class="z-0">
                <button type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2  bg-acajou text-sm font-medium
                        text-white focus:outline-none"
                        id="options-menu" aria-haspopup="true" aria-expanded="true"
                        @click="openDropdown(newGame)"
                >
                Pick
                <svg v-if="!newGame.dropdownOpen" class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                <svg v-else class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"  d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" clip-rule="evenodd"></path>
                </svg>
                </button>
              </div>

              <div v-if="newGame.dropdownOpen" class="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-acajou ring-1 ring-black ring-opacity-5 overflow-y-auto z-40" style="height: 30vh">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button v-for="game in gamesList" :key="game.id" class="block text-sm text-white hover:bg-russet font-bold w-full" role="menuitem" @click="assignGame(index, game)">{{game.name}}</button>
                </div>
              </div>
            </div>

        </div>
      </div>
      <button @click.prevent="createProduct" class="text-white font-bold border border rounded-lg mt-2 px-2 py-1">Create</button>
    </div>

    <div class="flex-col text-center text-indianyellow font-bold bg-oldburgundy w-1/4 rounded-r-lg p-2" style="height: 66vh">
      <div>New Game</div>
      <div class="flex items-center text-base p-1 mb-2 ">
        <div class="font-bold text-indianyellow w-1/2">Name</div>
        <label>
          <input class="text-white w-full bg-russet rounded outline-none text-center"  v-model="newGameName" required/>
        </label>
      </div>
      <button v-if="newGameName" @click.prevent="createGame" class="text-white font-bold border border rounded-lg px-2 py-1">Create</button>
    </div>


  </div>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "NewProduct",
    data() {
      return {
        numberOfGames: 1,
        gamesArray: [{dropdownOpen: false}],
        gamesList: '',
        productName: null,
        productPayout: null,
        productVisibleName: null,
        productCodsw: null,
        productInKey: null,
        productOutKey: null,
        newGameName: null,
        products: ''
      }
    },
    methods:{
      ...mapGetters([
        'getToken'
      ]),
      checkProductProperties(){
        let errors = []

        if(!this.productPayout || this.productPayout.toString().length !== 4){
          errors.push('Payout must be a 4 digit value. 1234 means 12.34%')
        }
        if(!this.productInKey || !this.productOutKey){
          errors.push('In and Out key are required')
        }
        if(this.numberOfGames < 1){
          errors.push('At least one game is required per product')
        }
        for (const [index, game] of this.gamesArray.entries()){
          if(!game.game){
            errors.push('Missing game at position ' + index)
          }
        }

        if(errors.length !== 0){
          this.$toasted.error(errors.join("</br>"))
          return false
        }

        for (const product of this.products){

          if (this.productName === product.name){
            errors.push('A product with that name already exists!')

          }
          if (this.productVisibleName === product.visibleName){
            errors.push('A product with that visible name already exists!')

          }
          if(product.codsw.includes(this.productCodsw)){
            errors.push('A product with that CODSW already exists!')
          }

          if(errors.length !== 0){
            this.$toasted.error(errors.join("</br>"))
            return false
          }
        }

        return true

      },
      createProduct(){
        if(this.checkProductProperties()){

          const filteredGamesArray = this.gamesArray.map(gameInfo => gameInfo.game)
          axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/product`,{
            name: this.productName,
            payout: this.productPayout,
            visibleName: this.productVisibleName,
            codsw: this.productCodsw,
            inKey: this.productInKey,
            outKey: this.productOutKey,
            games: filteredGamesArray
          },{
            headers: {
              'x-access-token': this.getToken()
            }
          })
          .then((response) => {
            this.$toasted.success(response.data.message)
            this.gamesArray= [{dropdownOpen: false}]
            this.numberOfGames= 1
            this.productName= null
            this.productPayout= null
            this.productVisibleName= null
            this.productCodsw= null
            this.productInKey= null
            this.productOutKey= null
            this.getProducts()
          })
          .catch(error => {
            this.$toasted.error(error)
          })
        }
      },
      getProducts() {
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/product`, {
          headers: {
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.products = response.data.products
          })
          .catch(error => {
            this.$toasted.error(error)
          })
      },
      getGames(){
        axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/game`, {
          headers:{
            'x-access-token': this.getToken()
          }
        })
          .then(response => {
            this.gamesList = response.data.games
          })
          .catch(error => {
            this.$toasted.error(error)
          })
      },
      assignGame(index, game) {
        this.gamesArray[index].game = game
        this.gamesArray[index].dropdownOpen = false

      },
      openDropdown(newGame){
        this.getGames()
        for(let game of this.gamesArray) {
          if(game === newGame)
            newGame.dropdownOpen = !newGame.dropdownOpen
          else
            game.dropdownOpen = false
        }

      },
      createGame(){
        axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/game`, {
          name: this.newGameName
        },
          {
            headers: {
              'x-access-token': this.getToken()
            }
        })
        .then(result => {
          this.$toasted.success(result.data.message)
          this.newGameName = ''
        })
        .catch(error => {
          this.$toasted.error(error)
        })
      }
    },
    watch: {
      numberOfGames(newNumber, oldNumber){
        if(!newNumber || newNumber <1){
          newNumber = 0
        }

        if(newNumber > oldNumber){
          for(let i = 0; i < newNumber-oldNumber; i++){
            this.gamesArray.push({dropdownOpen: false})
          }

        }
        if(oldNumber > newNumber){
          for(let i = 0; i < oldNumber- newNumber; i++) {
            this.gamesArray.pop()
          }
        }
      },
    },
    filters: {
      payoutFilter(value){
        return value.toFixed(2)
      }
    },
    mounted() {
      this.getGames()
      this.getProducts()
    }
  }
</script>

<style scoped>

</style>