<template>
  <div v-if="lastPage>1" class="flex justify-center items-center text-white text-md absolute inset-x-0 bottom-0 pb-4 focus:outline-none">
    <button class="mr-1 focus:outline-none" v-if="currentPage !== 1" v-html="'<<&nbsp'" @click.prevent="previousPage"></button>
    <pre class="mr-1 pointer-events-none" v-else>&nbsp;&nbsp;&nbsp;</pre>
    <div>{{currentPage}} of {{lastPage}}</div>
    <button class="ml-1 focus:outline-none" v-if="currentPage !== lastPage" v-html="'&nbsp>>'" @click.prevent="nextPage"></button>
    <pre class="ml-1 pointer-events-none" v-else >&nbsp;&nbsp;&nbsp;</pre>
  </div>
</template>

<script>
  export default {
    name: "PaginationFooter",
    props: [
      'lastPage',
      'currentPage'
    ],
    methods: {
      nextPage() {
        this.$emit('next-page')
      },
      previousPage() {
        this.$emit('previous-page')
      }
    }
  }
</script>

<style scoped>

</style>