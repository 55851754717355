<template>
  <nav class="flex items-center justify-between flex-wrap p-2">
    <router-link to="/">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <img class="h-10" :src="require('@/assets/logo.png')">
        <span class="font-bold text-xl pl-2 text-white">DB Uploader</span>
      </div>
    </router-link>

    <div class="w-full block flex-grow sm:flex sm:items-center sm:w-auto">

      <div class="text-sm sm:flex-grow text-white font-bold " v-if="loggedIn === true">
        <router-link class="block mt-4 sm:inline-block sm:mt-0 rounded text-white border hover:border-indianyellow hover:text-indianyellow rounded py-1 px-4 mr-2"
                     v-if="authorize('DbDecrypt')"
                     :to="{ name:'DbDecrypt' }">Decrypt Db
        </router-link>
        <router-link class="block mt-4 sm:inline-block sm:mt-0 rounded text-white border hover:border-indianyellow hover:text-indianyellow rounded py-1 px-4 mr-2"
                     v-if="authorize('Logs')"
                     :to="{ name: 'Logs' }">Logs</router-link>
        <router-link class="block mt-4 sm:inline-block sm:mt-0 rounded text-white border hover:border-indianyellow hover:text-indianyellow rounded py-1 px-4 mr-2"
                     v-if="authorize('SystemInfo')"
                     :to="{ name: 'SystemInfo' }">System Info</router-link>
        <router-link class="block mt-4 sm:inline-block sm:mt-0 rounded text-white border hover:border-indianyellow hover:text-indianyellow rounded py-1 px-4 mr-2"
                     v-if="authorize('NewProduct')"
                     :to="{ name: 'NewProduct'}">New Product</router-link>
      </div>
      <div v-else class="sm:flex-grow"></div>

      <div v-if="loggedIn !== true">
        <router-link class="inline-block text-sm px-4 py-2 leading-none rounded text-white border hover:border-indianyellow hover:text-indianyellow  mt-4 md:mt-0 font-bold" to="/">Login</router-link>
      </div>
      <div v-else>
        <button class="inline-block text-sm px-4 py-2 leading-none rounded text-white border hover:border-indianyellow hover:text-indianyellow  mt-4 md:mt-0 font-bold" @click="submitLogout">Logout</button>
      </div>
    </div>
  </nav>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import router from './router'

  export default {
    name: "Navbar",
    data() {
      return {
      }
    },
    methods: {
      ...mapActions([
        'doLogout'
      ]),
      authorize(routeName){
        return router.routesAuthorization[routeName].includes(this.getRole)
      },
      submitLogout () {
        this.doLogout()
        this.$router.push('/')
      },
    },
    computed: {
      ...mapGetters([
        'loggedIn',
        'getName',
        'getRole'
      ]),
    },

  }
</script>

<style scoped>

</style>